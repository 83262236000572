import { makeAutoObservable } from 'mobx';

class PageStore {
  //============= list
  listDefault = [];
  list = [];
  //============= search
  searchQuery = '';
  apiStatus: 'idle' | 'loading' | 'success' | 'error' = 'idle';
  //============= Pagination
  paginationTotalItem = 0;
  paginationLimit = 50;
  paginationSelected = 1;

  constructor() {
    makeAutoObservable(this);
  }
  setNewPage() {
    this.listDefault = [];
    this.list = [];
    this.searchQuery = '';
    this.paginationTotalItem = 0;
    this.paginationLimit = 50;
    this.paginationSelected = 1;
  }
  //============= list
  //-----до поиска
  setListDefault(newList: any) {
    this.list = newList;
    this.listDefault = newList;
    this.paginationTotalItem = newList.length;
    this.paginationSelected = 1;
  }
  //-----до в результате поиска
  setList(newList: any) {
    this.list = newList;
    this.paginationTotalItem = newList.length;
    this.paginationSelected = 1;
  }
  //============= Search
  setSearchQuery(query: string) {
    this.searchQuery = query;
    this.paginationSelected = 1;
  }
  //============= Pagination
  setPaginationDefault() {
    this.paginationSelected = 1;
  }
  setPaginationLimit(query: number) {
    this.paginationLimit = query;
    this.paginationSelected = 1;
  }
  setpaginationTotalItem(query: number) {
    this.paginationTotalItem = query;
    this.paginationSelected = 1;
  }
  setSelectedPage(page: number) {
    this.paginationSelected = page;
  }
  setSelectedNextPage() {
    this.paginationSelected = this.paginationSelected + 1;
  }
  //=============
  setApiStatus(status: 'idle' | 'loading' | 'success' | 'error') {
    this.apiStatus = status;
  }
}

const pageStore = new PageStore();
export default pageStore;
