//import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { ICLDAPTableUsersList, ICLDAPUsers, ICLDAPUsersDefault, TadfButtons } from '../models';
import { FCLDAPuserImportNew } from 'features/connectorLDAP/ui/UserImportNew';
import { FCLDAPuserLinkUpdate } from 'features/connectorLDAP/ui/UserLinkUpdate';
import { ELLDAPuserLogin } from 'entities/connectorLDAP/lib';
import { FUserActiveButton } from 'features/users/ui/UserActiveButton';
import { WPaginationBasic } from 'modules/m0_basic/Pagination/WPagination';
import { observer } from 'mobx-react-lite';
import pageStore from '../../../app/store/pageStore.mobx';
import { WSearchBasic } from 'modules/m0_basic/WSearch';
import { UIInfoLoader } from 'shared/ui/info';

export const WCLDAPUsersTable = observer(({ connector_id, upn_as_login, loading }: ICLDAPTableUsersList) => {
  const [modalData, setModalData] = useState<ICLDAPUsers>(ICLDAPUsersDefault);

  const [listPerPage, setListPerPage] = useState<ICLDAPUsers[]>([]);

  const TableHeader = () => {
    return (
      <tr>
        <th>LDAP</th>
        <th>adf</th>
        <th>login</th>
        <th>name</th>
        <th>lastname</th>
        <th>upn</th>
        <th>email</th>
        <th>groups</th>
      </tr>
    );
  };
  function AdfButtons({ item, index }: TadfButtons) {
    // disabled - статус в коннекторе. true - деактивирован
    return (
      <>
        {/*  disabled={item.disabled ? 'true' : 'false'}
        <br />
        local_active={item.local_active ? 'true' : 'false'}
        */}
        {item.disabled == true && item.local_id && item.local_active == true && <>{/* Кнопка деактивировать */ item.local_id && <FUserActiveButton user_id={item.local_id} active={true} />}</>}
        {item.upn != 'error' && item.disabled !== true && (
          <>
            {/*<FCLDAPImportUsersList key={index + item.login} user={item} connector_id={connector_id} active={item.local_active} upn_as_login={(upn_as_login == true) ? true : false} />*/}
            <div className="row col-ldap-buttons g-0">
              <div className="col-2 align-self-center  ">
                {item.local_active && item.local_active === true ? (
                  <>
                    <div className="  icon-align">
                      <i className="fas fa-lightbulb text-success " title="Активен в ADF"></i>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="  icon-align">
                      <i className="fas fa-lightbulb text-danger " title="Нективен в ADF"></i>
                    </div>
                  </>
                )}
              </div>
              <div className="col-10">
                {item.linked == null && (
                  <>
                    {/* кнопка Импортировать*/}
                    <FCLDAPuserImportNew id_random={index} userData={item} connector_id={connector_id} upn_as_login={upn_as_login == true ? true : false} />
                  </>
                )}
                {item.linked == true && <>Привязан</>}
                {item.linked == false && (
                  <>
                    {/* кнопка Привязать */}
                    <FCLDAPuserLinkUpdate id_random={index} user_id={item.local_id} login={ELLDAPuserLogin(item, upn_as_login == true ? true : false)} connector_id={connector_id} groups={item.groups} />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  const addOnModal = (item: ICLDAPUsers) => {
    setModalData(item);
  };
  function searchGo() {
    let list: ICLDAPUsers[] = pageStore.listDefault;
    let search = pageStore.searchQuery;
    if (search.length > 0) {
      const result = list.filter((item) => {
        return item?.login.includes(search) || item.name.includes(search) || item.lastname.includes(search) || item.upn.includes(search) || item.email.includes(search);
      });

      pageStore.setList(result);
    } else {
      pageStore.setList(pageStore.listDefault);
    }
  }
  function ListInPage() {
    let indexOfLastItem = pageStore.paginationSelected * pageStore.paginationLimit;
    let indexOfFirstItem = indexOfLastItem - pageStore.paginationLimit;
    let currentItems = pageStore.list.slice(indexOfFirstItem, indexOfLastItem);
    setListPerPage(currentItems);
  }
  useEffect(() => {
    searchGo();
  }, [pageStore.searchQuery]);

  useEffect(() => {
    ListInPage();
  }, [pageStore.paginationSelected]);

  useEffect(() => {
    searchGo();
  }, [pageStore.listDefault]);

  useEffect(() => {
    ListInPage();
  }, [pageStore.list]);

  return (
    <>
      <div className="panel">
        <div className="panel-container show">
          <div className="panel-content">
            <WSearchBasic />
            {/*
            <div className="form-group mb-20">
              <div className="input-group">
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Поиск по login, name, lastname, upn, email" aria-label="Recipient's username" aria-describedby="button-addon5" />
                <div className="input-group-append">
                  <button className="btn btn-outline-default" type="button" id="button-addon5" onClick={() => searchClean()}>
                    <i className="fal fa-times"></i>
                  </button>
                </div>
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button" id="button-addon5" onClick={() => searchGo()}>
                    <i className="fal fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row mt-3 mb-3 alert alert-primary alert-dismissible text-center p-0 ">
              <div className="d-block text-truncate text-truncate-lg  p-2">Result:</div>
              <div className="d-block text-truncate text-truncate-lg  p-2">
                строка поиска: <b>{searchInfo.current ? searchInfo.current : 'все'}</b>
              </div>
              <div className="d-block text-truncate text-truncate-lg  p-2">
                найдено: <b>{listResult.length}</b>
              </div>
            </div>
            */}
            <WPaginationBasic />
            {!pageStore.listDefault.length && !loading && <div className="alert alert-primary alert-dismissible text-center">Данных нет</div>}
            {loading && (
              <div className="alert alert-primary alert-dismissible text-center">
                <UIInfoLoader /> Данные загружаются
              </div>
            )}
            {!loading && pageStore.list && pageStore.list.length > 0 && (
              <>
                <table id="dt-basic-example" className="table table-bordered table-hover table-striped w-100  ">
                  <thead className="bg-primary-600">
                    <TableHeader />
                  </thead>
                  <tbody>
                    {listPerPage.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.disabled === true ? <i className="fas fa-address-card text-danger" title="деактивирован в ldap"></i> : <i className="fas fa-address-card text-success" title="активен в ldap"></i>}</td>
                          <td>
                            <AdfButtons item={item} index={item.sid} />
                          </td>
                          <td>{item.login}</td>
                          <td>{item.name}</td>
                          <td>{item.lastname}</td>
                          <td>{item.upn}</td>
                          <td>{item.email}</td>
                          <td>
                            <div className="row">
                              <div className="col-6 align-middle pt-2">{item.groups.length} </div>
                              <div className="col-6">
                                <button className="btn btn-outline-primary  waves-effect waves-themed" data-toggle="modal" data-target=".default-example-modal-right" onClick={() => addOnModal(item)}>
                                  <i className="fal fa-info"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <TableHeader />
                  </tfoot>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
      {/*  Modal*/}
      {modalData && (
        <div className="modal fade default-example-modal-right" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-right">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title h4">Пользователь: {modalData.login}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModalData(ICLDAPUsersDefault);
                  }}
                >
                  <span aria-hidden="true">
                    <i className="fal fa-times"></i>
                  </span>
                </button>
              </div>
              <div className="modal-body audit-detail">
                <div className="row">
                  <div className="col-6">
                    <h3>Данные</h3>
                  </div>
                  <div className="col-6 text-right">
                    {modalData.local_id ? (
                      <>
                        <a href={`/user/update/${modalData.local_id}`} className="btn  btn-primary waves-effect waves-themed mb-3" title="Редактировать" target="_blank">
                          Редактировать <i className="fa fa-external-link"></i>
                        </a>
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
                <table className="table table-bordered table-hover table-striped w-100">
                  <tbody>
                    <tr>
                      <td>sid</td>
                      <td>{modalData.sid}</td>
                    </tr>
                    <tr>
                      <td>login</td>
                      <td>{modalData.login}</td>
                    </tr>
                    <tr>
                      <td>name</td>
                      <td>{modalData.name}</td>
                    </tr>
                    <tr>
                      <td>lastname</td>
                      <td>{modalData.lastname}</td>
                    </tr>
                    <tr>
                      <td>upn</td>
                      <td>{modalData.upn}</td>
                    </tr>
                    <tr>
                      <td>email</td>
                      <td>{modalData.email}</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <h3 className="color-primary-300">
                  {/*color-primary-300*/}
                  <b>
                    Группы
                    {modalData.groups && <span className="badge bg-primary-300 ml-2">{modalData.groups.length}</span>}
                  </b>
                </h3>
                <table className="table table-bordered table-hover table-striped w-100">
                  <tbody>
                    {modalData.groups &&
                      modalData.groups.map((itemGroup: any) => {
                        return (
                          <>
                            <tr>
                              <td>{itemGroup}</td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
