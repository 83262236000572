import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { UIInfoNoAccess } from 'shared/ui/info';
import { EUsersGroupsModal } from 'entities/users/ui';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { UIAlertError, UIAlertErrorSM, UIAlertSuccess, UIAlertSuccessSM } from 'shared/ui/alert';
import { ApiUserTgEmailSend } from 'entities/users/api';
import moment from 'moment';
import { useHttp } from 'hooks/http.hooks';
import { VerifySystem, dataPagesMenu } from 'app/data/default';
import { LANG_default } from 'app/data/lang';
import { usePageContext } from 'shared/context/page';
import { WUsersUpdateProfile } from './widgetsProfile';
import { IUserUpdate, IUserUpdateDefault } from '../models';
import { useAuthContext } from 'shared/context/auth';
import { WUsersUpdateTimeLocked } from './WTimeLocked';

export const UsersPageUpdate = () => {
  const pageCode: string = 'users.update';
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const language = 'ru';
  //============= Date update
  function funcDateNow() {
    const dateU = new Date().toISOString().slice(0, 16).toString().replace('T', ' ');
    return dateU;
  }
  const [dataupdate, setDataUpdate] = useState(funcDateNow());

  //=============
  const params = useParams(); // из url : id
  //console.log(params.id); // "hotspur"
  const dataPage = dataPagesMenu['users.update'];
  //=============
  const { loading, request, error } = useHttp();

  //const location = useLocation();
  //console.log('location', location.search);
  //============
  const [userdata, setUserData] = useState<IUserUpdate>(IUserUpdateDefault);
  const [userdataold, setUserDataOld] = useState<IUserUpdate>(IUserUpdateDefault);
  const [flagСhanged, setFlagСhanged] = useState(false);
  const [mtelegram, setmtelegram] = useState({
    _id: { $oid: '' },
    is_linked: false,
    link_id: 0,
    username_tg: '',
    link_valid: '',
  });
  const [adfpassword, setAdfPassword] = useState('');
  const [verify_pass, setVerifyPass] = useState('External');
  const { updatePage, loadingPage, errorPage } = usePageContext();

  //=========================  Форма
  //------------ Отмена перезагрузки формы
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
  };
  //========================== Form Messame
  const [msgsuccess, setMsgSuccess] = useState('');
  const [msgerror, setMsgError] = useState('');

  const [msg_error_status, setMsgErrorStatus] = useState('');
  const [msg_success_status, setMsgSuccessStatus] = useState('');
  //setMsgErrorStatus / setMsgSuccessStatus
  //---
  const [msg_error_profile, setMsgErrorProfile] = useState('');
  const [msg_success_profile, setMsgSuccessProfile] = useState('');

  //-------------
  function cleanMsg() {
    setMsgErrorStatus('');
    setMsgSuccessStatus('');
  }
  //------------ обновление данных формы
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userdata, [event.target.name]: event.target.value });
    console.log('userdata', userdata);
  };
  const changeSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setUserData({ ...userdata, [event.target.name]: event.target.value });
    console.log('userdata', userdata);
  };
  const changePassw = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdfPassword(event.target.value);
  };
  const changeVerifyPass = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setVerifyPass(event.target.value);
  };
  //=============

  //============= API request
  const getUserData = useCallback(async () => {
    try {
      cleanMsg();
      const data = await request('/api/user/' + params.id, 'GET', {});
      if (data.data) {
        // data.data.verifypass=data.data.verify_pass;
        setUserDataOld(data.data);
        setUserData(data.data);
        console.log(data.data);
        setmtelegram(data.data.methods.telegram);
        setVerifyPass(data.data.verify_pass);
      }
      if (error) {
        setMsgErrorStatus(error);
      }
      setDataUpdate(funcDateNow());
    } catch (e) {
      console.log(e);
      setMsgErrorStatus(LANG_default[language].error500);
    }
  }, [request]);

  //============
  const saveHandler = async () => {
    try {
      setMsgErrorProfile('');
      setMsgSuccessProfile('');
      console.log(userdata);
      if (userdata.login == '') {
        setMsgError('Логин - обязательное поле для заполнения');
        //message('Логин - обязательное поле для заполнения');
      }
      if (userdata.login != '') {
        if (userdataold.name != userdata.name) {
          const data = await request('/api/user/' + params.id + '/update', 'PUT', {
            name: userdata.name,
          });
        }
        if (userdataold.email != userdata.email) {
          const data = await request('/api/user/' + params.id + '/update', 'PUT', {
            email: userdata.email,
          });
        }
        if (userdataold.role != userdata.role) {
          const data = await request('/api/user/' + params.id + '/update', 'PUT', {
            role: userdata.role,
          });
        }
        if (error) {
          setMsgErrorProfile(error);
        } else {
          setMsgSuccessProfile('Данные успешно сохранены');
          setFlagСhanged(!flagСhanged);
        }

        //const data = await request('/api/user/' + params.id + '/update', 'PUT', json);
        // console.log('data', data);
      }
    } catch (e) {
      console.log(e);
      setMsgErrorStatus(LANG_default[language].error500);
    }
  };
  //============
  // .route("/:uid/password", patch(change_user_password))
  // в случае с ...password ожидается документ: {password: String}  - остальные поля проигнорируются
  const saveSystemAuth = async () => {
    setMsgError('');
    setMsgSuccess('');
    if (userdata.verify_pass != verify_pass) {
      try {
        const data = await request('/api/user/' + params.id + '/update', 'PUT', {
          verify_pass: verify_pass,
        });
        console.log('data', data);
        setMsgSuccess('Система аутентификации успешно изменена');
        setFlagСhanged(!flagСhanged);
      } catch (e) {
        console.log(e);
      }
    }
    console.log('verify_pass', verify_pass);
    if (verify_pass == 'Internal') {
      try {
        if (adfpassword == '') {
          setMsgError('Пароль не может быть пустым полем');
        }
        if (adfpassword != '') {
          const data = await request('/api/user/' + params.id + '/password', 'PATCH', {
            password: adfpassword,
          });
          //console.log('saveSystemAuth data', data);
          setMsgSuccess('Пароль успешно изменен');
          setFlagСhanged(!flagСhanged);
          setAdfPassword('');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  //============== СТАТУС
  const FunctionDeActivate = async () => {
    try {
      setMsgError('');
      setMsgSuccess('');
      const data = await request('/api/user/' + params.id + '/deactivate', 'PATCH', {});
      getUserData();
      setMsgSuccessStatus('Пользователь деактивирован');
    } catch (e) {
      console.log(e);
      setMsgErrorStatus(LANG_default[language].error500);
    }
  };
  const FunctionActivate = async () => {
    try {
      setMsgError('');
      setMsgSuccess('');
      const data = await request('/api/user/' + params.id + '/activate', 'PATCH', {});
      getUserData();
      setMsgSuccessStatus('Пользователь активирован');
    } catch (e) {
      console.log(e);
      setMsgErrorStatus(LANG_default[language].error500);
    }
  };
  //========= .route("/:uid/unarchive", patch(unarchive_user))
  const FunctionUnArchive = async () => {
    try {
      setMsgError('');
      setMsgSuccess('');
      const data = await request('/api/user/' + params.id + '/unarchive', 'PATCH', {});
      getUserData();
      setMsgSuccessStatus('Пользователь разархивирован');
    } catch (e) {
      console.log(e);
      setMsgErrorStatus(LANG_default[language].error500);
    }
  };
  const FunctionArchive = async () => {
    try {
      setMsgError('');
      setMsgSuccess('');
      const data = await request('/api/user/' + params.id + '/archive', 'PATCH', {});
      getUserData();
      setMsgSuccessStatus('Пользователь архивирован');
    } catch (e) {
      console.log(e);
      setMsgErrorStatus(LANG_default[language].error500);
    }
  };
  //======
  const FunctionTelegramUnlink = async () => {
    try {
      setMsgError('');
      setMsgSuccess('');
      const data = await request('/api/user/' + params.id + '/methods/telegram/unlink', 'DELETE', {
        _id: mtelegram._id['$oid'],
      });
      getUserData();
      setMsgSuccess('Линковка к телеграму удалена');
    } catch (e) {
      console.log(e);
    }
  };
  // отправить письмо со ссылкой на линковку tg
  // ApiUserTgEmailSend
  const { loading: loadingTgEmail, response: apiTgEmail, error: errorTgEmail, success: successTgEmail } = ApiUserTgEmailSend(); //  API
  const FunctionTgEmailSend = async () => {
    try {
      const res = await apiTgEmail(params.id);
      // if (error) { setErrorPage(error); }
    } finally {
    }
  };
  //============= отслеживание
  useEffect(() => {
    //document.title = dataPage.name;
    // getUserData();
  }, [flagСhanged]);
  useEffect(() => {
    document.title = dataPage.name;
    getUserData();
  }, [updatePage, flagСhanged]);
  //============
  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loadingPage} />
      <UITypographyH1 pageCode={pageCode} loading={loadingPage} addtoname={'"' + userdata.login + '"'} />
      {errorPage && <UIAlertError text={errorPage} />}

      {(msgsuccess || error || msgerror) && (
        <>
          {msgsuccess && <UIAlertSuccess text={msgsuccess} />}
          {error && <UIAlertError text={error} />}
          {msgerror && <UIAlertError text={msgerror} />}
        </>
      )}
      <div className="row">
        <div className="col-md-6 ">
          {/*offset-md-3*/}
          <div id="panel-4" className="panel">
            <WUsersUpdateProfile userdata={userdata} />
          </div>
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>
                Статус
                <span className="fw-300">
                  <i> </i>
                </span>
              </h2>
              <div className="panel-toolbar"></div>
            </div>
            <div className="panel-container show">
              {msg_error_status && <UIAlertError text={msg_error_status} />}
              {msg_success_status && <UIAlertSuccess text={msg_success_status} />}
              <div className="panel-content">
                <table className="table table-striped m-0">
                  <tbody>
                    <tr>
                      <td>Статус:</td>
                      <td>
                        {!userdata.archive && (
                          <>
                            {userdata.active && (
                              <>
                                <i className="fas fa-lightbulb text-success"></i> Активен
                              </>
                            )}
                            {!userdata.active && (
                              <>
                                <i className="fas fa-lightbulb text-secondary"></i> Не активен
                              </>
                            )}
                          </>
                        )}
                        {userdata.archive && (
                          <>
                            <i className="fas fa-archive text-danger"></i> Архивирован
                          </>
                        )}
                      </td>
                      <td className="text-right">
                        {!userdata.archive && (
                          <>
                            {userdata.active && (
                              <>
                                <button type="button" className="btn btn-sm btn-outline-danger waves-effect waves-themed mr-1" onClick={FunctionDeActivate}>
                                  Деактивировать
                                </button>
                              </>
                            )}
                            {!userdata.active && (
                              <>
                                <button type="button" className="btn btn-sm btn-outline-success waves-effect waves-themed mr-1" onClick={FunctionActivate}>
                                  Активировать
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </td>

                      <td>
                        {!userdata.archive && (
                          <>
                            <button type="button" className=" btn btn-sm btn-outline-danger waves-effect waves-themed" onClick={FunctionArchive}>
                              <span className="fas fa-archive mr-1"></span>
                              Архивировать
                            </button>
                          </>
                        )}
                        {userdata.archive && (
                          <>
                            <button type="button" className=" btn btn-sm btn-outline-success waves-effect waves-themed" onClick={FunctionUnArchive}>
                              <span className="fas fa-archive mr-1"></span>
                              Разархивировать
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                    <WUsersUpdateTimeLocked userdata={userdata} />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <div id="panel-2" className="panel">
            <div className="panel-hdr">
              <h2>
                Аутентификация
                <span className="fw-300">
                  <i> </i>
                </span>
              </h2>
              <div className="panel-toolbar"></div>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row ">
                  <div className="col-12 ">
                    <form onSubmit={submitHandler}>
                      <table className="table table-striped m-0">
                        <tbody>
                          <tr>
                            <th>
                              <label className="form-label" htmlFor="verifypass">
                                Аутентификация через
                              </label>
                            </th>
                            <td>
                              {/*VerifySystem.map(item => (
                                                                <>
                                                                    {item.value == userdata.verify_pass && <div>{item.name}</div>}
                                                                </>
                                                            ))*/}

                              <select className="form-control" id="verifypass" name="verify_pass" onChange={changeVerifyPass} value={verify_pass}>
                                {VerifySystem.map((item) => (
                                  <option key={item.value} value={item.value}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                          {verify_pass == 'Internal' && (
                            <tr>
                              <td>
                                <label className="form-label" htmlFor="adfpassword">
                                  Пароль
                                </label>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input type="password" className="form-control" id="adfpassword" name="adfpassword" onChange={changePassw} />
                                </div>
                              </td>
                            </tr>
                          )}
                          {verify_pass == 'External' && (
                            <>
                              <tr>
                                <td>
                                  <label className="form-label" htmlFor="adfpassword">
                                    Коннектор
                                  </label>
                                </td>
                                <td>
                                  <div className="form-group">{userdata.connector}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label className="form-label" htmlFor="adfpassword">
                                    {' '}
                                    sid
                                  </label>
                                </td>
                                <td>
                                  <div className="form-group">{userdata.sid}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Группы</b>
                                </td>
                                <td>
                                  <EUsersGroupsModal index={Number(params.id)} login={userdata.login} groups={userdata.groups} />
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </form>
                    <hr className="mt-30" />
                    <div className="row">
                      <div className="col-6">
                        <Link to="/user" className="btn btn-default waves-effect waves-themed">
                          Отменить
                        </Link>
                      </div>
                      <div className="col-6 text-right">
                        <button type="submit" className="btn btn-primary waves-effect waves-themed" onClick={saveSystemAuth} disabled={loading}>
                          Сохранить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(userdata.expiry || userdata.password_expiry) && (
            <div id="panel-3" className="panel">
              <div className="panel-hdr">
                <h2>
                  Сроки действия
                  <span className="fw-300">
                    <i> </i>
                  </span>
                </h2>
                <div className="panel-toolbar"></div>
              </div>
              <div className="panel-container show">
                <div className="panel-content">
                  <div className="row ">
                    <div className="col-12 ">
                      <table className="table table-striped m-0">
                        <tbody>
                          {userdata.expiry && (
                            <tr>
                              <td>Учетной записи:</td>
                              <td>
                                {userdata.expiry == null && <>нет</>}
                                {userdata.expiry != null && (
                                  <>
                                    <span className="text-primary">{moment(Number(userdata.expiry.$date.$numberLong)).local().format('DD.MM.YYYY HH:mm:ss')}</span>
                                  </>
                                )}
                              </td>
                            </tr>
                          )}
                          {userdata.password_expiry && (
                            <tr>
                              <td>Пароля:</td>
                              <td>
                                {userdata.password_expiry == null && <>нет</>}
                                {userdata.password_expiry != null && (
                                  <>
                                    <span className="text-primary">{moment(Number(userdata.password_expiry.$date.$numberLong)).local().format('DD.MM.YYYY HH:mm:ss')}</span>
                                  </>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div id="panel-3" className="panel">
            <div className="panel-hdr">
              <h2>
                Методы
                <span className="fw-300">
                  <i> </i>
                </span>
              </h2>
              <div className="panel-toolbar"></div>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row ">
                  <div className="col-12 ">
                    {/*mtelegram._id['$oid']*/}
                    <table className="table table-striped m-0">
                      <tbody>
                        {!mtelegram && (
                          <tr>
                            <td>telegram</td>
                            <td>нет линковки</td>
                            {errorTgEmail && <UIAlertErrorSM text={errorTgEmail} />}
                            {successTgEmail && <UIAlertSuccessSM text={successTgEmail} />}
                            {!successTgEmail && (
                              <button type="button" className=" btn btn-sm btn-success waves-effect waves-themed" onClick={FunctionTgEmailSend}>
                                Активировать через email
                              </button>
                            )}
                          </tr>
                        )}
                        {mtelegram && (
                          <>
                            <tr>
                              <td>telegram</td>
                              <td>{mtelegram.is_linked ? 'активен' : 'не активен'}</td>
                              <td></td>
                            </tr>
                            {mtelegram.is_linked && (
                              <tr>
                                <td>
                                  <small>id:</small>
                                  {mtelegram.link_id}
                                </td>
                                <td>
                                  <small>name:</small>
                                  {mtelegram.username_tg}
                                </td>
                                <td className="text-right">
                                  <button type="button" className=" btn btn-sm btn-outline-danger waves-effect waves-themed" onClick={FunctionTelegramUnlink}>
                                    Удалить
                                  </button>
                                </td>
                              </tr>
                            )}
                            {mtelegram.link_valid && !mtelegram.is_linked && (
                              <tr>
                                <td>
                                  <b>ссылка активна до:</b>
                                </td>
                                <td>{moment(mtelegram.link_valid).local().format('DD.MM.YYYY HH:mm:ss')}</td>
                                <td className="text-right">
                                  <button type="button" className=" btn btn-sm btn-outline-danger waves-effect waves-themed" onClick={FunctionTelegramUnlink}>
                                    Удалить
                                  </button>
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
