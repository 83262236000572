import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { dataDefault } from '../app/data/default';
import { usePageContext } from 'shared/context/page';
import { useAuthContext } from 'shared/context/auth';

export function MenuLeft() {
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  const { globalBypassavail } = usePageContext();
  const location = useLocation();
  const arrPath = location.pathname.split('/');
  //const [bypassavail, setbypassavail] = useState(false);
  /*
  const bypassavail = useRef<boolean>(false); // bypassavail.current

  function ShowMenuBypass() {
    const statusbypass = SLocalstoreGetBypass();
    if (statusbypass) {
      if (statusbypass.bypassavail != bypassavail.current) {
        bypassavail.current = statusbypass.bypassavail;
      }
    }
  }
*/
  useEffect(() => {
    /*
    const timerId = setInterval(() => {
      ShowMenuBypass();
    }, 5000);
    */
  }, []);

  //----
  return (
    <>
      <aside className="page-sidebar">
        <div className="page-logo">
          <a href="#!" className="page-logo-link  d-flex align-items-center position-relative">
            <img src={dataDefault.logoimg} alt={dataDefault.project} aria-roledescription="logo" />
            <span className="page-logo-text mr-1">{dataDefault.project}</span>
          </a>
        </div>

        <nav id="js-primary-nav" className="primary-nav" role="navigation">
          <div className="nav-filter">
            <div className="position-relative">
              <input type="text" id="nav_filter_input" placeholder="Filter menu" className="form-control" tabIndex={0} />
              <a
                href="#!"
                onClick={() => {
                  return false;
                }}
                className="btn-primary btn-search-close js-waves-off"
                data-action="toggle"
                data-class="list-filter-active"
                data-target=".page-sidebar"
              >
                <i className="fal fa-chevron-up"></i>
              </a>
            </div>
          </div>
          <div className="info-card">
            <img src="/assets/img/avatar-admin.png" className="profile-image rounded-circle" alt=" " />
            <i className="fa-solid fa-user"></i>
            <div className="info-card-text">
              <a href="#!" className="d-flex align-items-center text-white">
                <span className="text-truncate text-truncate-sm d-inline-block">{auth.userlogin}</span>
              </a>
              <span className="d-inline-block text-truncate text-truncate-sm">{auth.userrole}</span>
            </div>
            <img src="/assets/img/card-backgrounds/cover-2-lg.png" className="cover" alt="cover" />
          </div>
          <ul id="js-nav-menu" className="nav-menu">
            {/*<li className="active">
                                <a href="blank.html" title="Blank Project" data-filter-tags="blank page">
                                    <i className="fal fa-globe"></i>
                                    <span className="nav-link-text" data-i18n="nav.blankpage"> Project 1</span>
                                </a>
                            </li> */}
            <li className="nav-title">Меню</li>

            <li>
              <NavLink to="/" title="Category" data-filter-tags="Dashboard">
                <i className="fal fa-desktop"></i>
                <span className="nav-link-text">Dashboard</span>
              </NavLink>
            </li>
            {/* =========================== Аудит  */}
            {auth.accessRights('audit.daily') == true && (
              <li className={location.pathname === '/audit' || location.pathname === '/audit/hour' || location.pathname === '/audit/day' || location.pathname === '/audit/period' || location.pathname === '/audit/config' ? 'active' : undefined}>
                <NavLink to="/audit" title="Audit" data-filter-tags="Audit">
                  <i className="fal fa-ballot"></i>
                  <span className="nav-link-text">Аудит</span>
                </NavLink>
                <ul>
                  <li className={location.pathname === '/audit/hour' ? 'active' : undefined}>
                    <NavLink to="/audit/hour" title=" " data-filter-tags="utilities menu child">
                      <span className="nav-link-text">Логи за час</span>
                    </NavLink>
                  </li>
                  <li className={location.pathname === '/audit' || location.pathname === '/audit/day' ? 'active' : undefined}>
                    <NavLink to="/audit/day" title=" " data-filter-tags="utilities disabled item">
                      <span className="nav-link-text">Логи за день</span>
                    </NavLink>
                  </li>
                  <li className={location.pathname === '/audit/period' ? 'active' : undefined}>
                    <NavLink to="/audit/period" title=" " data-filter-tags="utilities disabled item">
                      <span className="nav-link-text">Логи за период</span>
                    </NavLink>
                  </li>
                  <li className={location.pathname === '/audit/config' ? 'active' : undefined}>
                    <NavLink to="/audit/config" title=" " data-filter-tags="utilities disabled item">
                      <span className="nav-link-text">Конфигурация</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            {/* =========================== Пользователи  */}
            {auth.accessRights('users') == true && (
              <li className={location.pathname === '/user' || location.pathname === '/report/not-activated' || location.pathname === '/report/not-linked' ? 'active' : undefined}>
                <NavLink to="/user" title="Пользователи" data-filter-tags="user">
                  <i className="fal fa-users"></i>
                  <span className="nav-link-text">Пользователи </span>
                </NavLink>
                <ul>
                  <li className={location.pathname === '/user' ? 'active' : undefined}>
                    <NavLink to="/user" title=" " data-filter-tags="utilities menu child">
                      <span className="nav-link-text">Все пользователи</span>
                    </NavLink>
                  </li>
                  <li className={location.pathname === '/report/not-activated' ? 'active' : undefined}>
                    <NavLink to="/report/not-activated" title=" " data-filter-tags="utilities menu child">
                      <span className="nav-link-text">Телеграм не активирован</span>
                    </NavLink>
                  </li>
                  <li className={location.pathname === '/report/not-linked' ? 'active' : undefined}>
                    <NavLink to="/report/not-linked" title=" " data-filter-tags="utilities menu child">
                      <span className="nav-link-text">Телеграм не прилинкован</span>
                    </NavLink>
                  </li>
                </ul>
                {/* <ul>
                                <li className={location.pathname === '/user/new' ? 'active' : undefined} >
                                    <NavLink to="/user/new" title=" " data-filter-tags="utilities menu child">
                                        <span className="nav-link-text" >Добавить</span>
                                    </NavLink>
                                </li>
                            </ul>*/}
              </li>
            )}
            {/* =========================== Пользователи. Отчеты  
                        {auth.accessRights("report.tg.notactivated") == true &&
                            <li className={location.pathname === '/report' 
                            ||  location.pathname === '/report/not-activated'
                                ? 'active' : undefined}
                            >
                                <NavLink to="/report/not-activated" title="Телеграм не активирован" data-filter-tags="user">
                                    <i className="fal fa-clipboard-list"></i>
                                    <span className="nav-link-text" >Отчет. Телеграм не активирован </span>
                                </NavLink>
                                 
                            </li>
                        }
                        */}
            {/* =========================== Настройки  */}
            {(auth.accessRights('settings.сonnectors.ldap.list') == true || auth.accessRights('settings.notification.email.servers.list') == true || auth.accessRights('settings.bypass') == true) && (
              <li className={arrPath[1] == 'settings' ? 'active' : undefined}>
                <NavLink to="/settings" title="Настройки" data-filter-tags="settings">
                  <i className="fal fa-cogs"></i>

                  <span className="nav-link-text">Настройки </span>
                </NavLink>
                <ul>
                  <li className={location.pathname == '/settings' || arrPath[2] == 'connector-ldap' ? 'active' : undefined}>
                    <NavLink to="/settings/connector-ldap" title=" " data-filter-tags="utilities menu child">
                      <span className="nav-link-text">LDAP-коннекторы</span>
                    </NavLink>
                  </li>
                  {/* 
                  <li className={arrPath[2] === 'notification-email' ? 'active' : undefined} >
                     <NavLink to="/settings/notification-email/servers/list" title=" " data-filter-tags="utilities menu child">
                        <span className="nav-link-text" >Email</span>
                    </NavLink>
                  </li>
                  */}
                  {globalBypassavail && (
                    <>
                      <li className={location.pathname === '/settings/emergency-bypass' ? 'active' : undefined}>
                        <NavLink to="/settings/emergency-bypass" title="Bypass" data-filter-tags="bypass">
                          <i className="fal fa-asterisk  "></i>
                          <span className="nav-link-text  ">Emergency bypass</span>
                          <span className="dl-ref   hidden-nav-function-minify hidden-nav-function-top badge-pill ">{/*    <i className="fal fa-toggle-on fa-2x"></i>*/}</span>
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            )}
          </ul>
          <div className="filter-message js-filter-message bg-success-600"></div>
        </nav>
      </aside>
    </>
  );
}
