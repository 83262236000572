import { userRoles } from 'app/data/default';
import { ELLDAPuserLogin } from 'entities/connectorLDAP/lib';
import { ICLDAPUsers } from 'modules/connectorLDAP/models';
import { ApiUserNew, ApiUserSearch } from 'entities/users/api';
import { IUserItem, IUsersNew } from 'entities/users/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { devLog } from 'shared/lib/default';
import { DataHttp } from 'shared/types';
import { UIAlertError, UIAlertSuccess, UIAlertSuccessSM } from 'shared/ui/alert';
import { UIInfoLoader } from 'shared/ui/info';

/*
interface IFCLDAPuser {
    login: string;
}

export function FCLDAPuserImport({ login }: IFCLDAPuser) {

    const [list, setlist] = useState<IUserItem[]>([]);
    const {loading, UserSearchData, error, success } = ApiUserSearch({ 'login': login });
    const dataList = useCallback(
        async () => {
            try {
                const res: DataHttp = await UserSearchData();
                setlist(res.data);
                if (res.data) devLog('res.data', res.data.toString());
            } catch (e) { if (e instanceof Error) { devLog('catch e ', e.message); } }
        }, [UserSearchData]
    );
    //---
    useEffect(() => {
        dataList();
    }, []);
    if (list.length == 0) {
        return (
            <>
                <div >
                <FCLDAPuserImportNew userItem={list[0]} />
                    <button 
                    className="btn btn-primary btn-block waves-effect waves-themed"
                    onClick={ButtonImportUser}
                    disabled={loading}
                    >Импортировать</button>
                </div>
            </>
        );
    }
    return (
        <>
            {error && <UIAlertError text={error} />}
            {loading && <UIInfoLoader />}
            {list.length > 0 && <>
                {list.map((item, index) => {
                    return (
                        <div key={index}>
                            {item.connector == null && <div > {item._id}
                                <button className="btn btn-warning btn-block waves-effect waves-themed" >Связать</button>
                            </div>}
                        </div>);
                })}
            </>}

        </>
    )
}
*/
interface IFCLDAPuserItem {
  id_random: number;
  userData: ICLDAPUsers;
  connector_id: string;
  upn_as_login: boolean;
}
export function FCLDAPuserImportNew({ userData, connector_id, upn_as_login, id_random }: IFCLDAPuserItem) {
  //devLog('FCLDAPuserImportNew upn_as_login',JSON.stringify(upn_as_login));
  const [list, setlist] = useState<IUserItem[]>([]);
  const [showButton, setShowButton] = useState(true);
  var required = true;
  //----
  const dataArray = useMemo(() => {
    let dArray: IUsersNew = {
      login: ELLDAPuserLogin(userData, upn_as_login == true ? true : false),
      email: userData.email == '' ? userData.upn : userData.email,
      verify_pass: 'External',
      connector: connector_id,
      role: userRoles[0].value,
      groups: userData.groups,
      sid: userData.sid,
    };
    if (userData.name == '') {
      required = false;
    } else {
      dArray.name = userData.name;
      if (userData.lastname != '') {
        dArray.name = dArray.name + ' ' + userData.lastname;
      }
    }
    if (userData.email == '') {
      required = false;
    } else {
      dArray.email = userData.email;
    }
    return dArray;
  }, []);

  //devLog('FCLDAPuserImportNew dataArray',JSON.stringify(dataArray));

  //---
  const { loading, response: PostUserAdd, error, success } = ApiUserNew();
  //---
  const ButtonUserAdd = useCallback(async () => {
    try {
      devLog('ButtonUserAdd', JSON.stringify(dataArray));
      const res = await PostUserAdd(dataArray);
      //setlist(res.data);
      setShowButton(false);
      if (res.data) devLog('res.data', res.data.toString());
    } catch (e) {
      if (e instanceof Error) {
        devLog('catch e ', e.message);
      }
    }
  }, [PostUserAdd]);
  //---

  return (
    <>
      {error && <UIAlertError text={error} />}
      {success && <UIAlertSuccessSM text={success} />}
      {showButton && (
        <button className="btn btn-primary btn-block waves-effect waves-themed" onClick={ButtonUserAdd} disabled={loading}>
          Импортировать
        </button>
      )}
    </>
  );
}
