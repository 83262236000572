import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import pageStore from '../../app/store/pageStore.mobx';
// <WPaginationBasic />

export const WSearchBasic: React.FC = observer(() => {
  const [search, setSearch] = useState<string>('');

  function searchClean() {
    setSearch('');
    pageStore.setList(pageStore.listDefault);
  }
  function searchGo() {
    if (search.length > 0) {
      pageStore.setSearchQuery(search);
    }
  }

  return (
    <>
      <div className="form-group mb-20">
        <div className="input-group">
          <input type="text" value={search} defaultValue={pageStore.searchQuery} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Поиск по login, name, lastname, upn, email" aria-label="Recipient's username" aria-describedby="button-addon5" />
          <div className="input-group-append">
            <button className="btn btn-outline-default" type="button" id="button-addon5" onClick={() => searchClean()}>
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="input-group-append">
            <button className="btn btn-primary" type="button" id="button-addon5" onClick={() => searchGo()}>
              <i className="fal fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row mt-3 mb-3 alert alert-primary alert-dismissible text-center p-0 ">
        <div className="d-block text-truncate text-truncate-lg  p-2">Result:</div>
        <div className="d-block text-truncate text-truncate-lg  p-2">
          строка поиска: <b>{pageStore.searchQuery ? pageStore.searchQuery : 'все'}</b>
        </div>
        <div className="d-block text-truncate text-truncate-lg  p-2">
          найдено: <b>{pageStore.paginationTotalItem}</b>
        </div>
      </div>
    </>
  );
});
